<template>
  <div class='mobile-otp-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div class='mobile-otp-title'>
          Confirm OTP
          <mobile-outlined
            style='
              font-size: 1.5rem;
              margin-left: 0.5rem;
              color: rgba(0, 0, 0, 0.85);
              vertical-align: inherit;
            '
          />
        </div>
        <div class='mobile-otp-sub-description'>
          Sent to +91 {{ mobileNumber }}
          <span @click='onClickChange' class='mobile-otp-change-number-text'
            >(<u>change</u>)</span
          >
        </div>
        <div class='mobile-otp-input-btn-wrapper'>
          <div
            style='display: flex; flex-direction: row; justify-content: center'
          >
            <v-otp-input
              ref='otpInput'
              separator=''
              input-classes='otp-input'
              :num-inputs='6'
              :should-auto-focus='true'
              :is-input-num='true'
              @on-change='handleOnChange'
              @on-complete='handleOnComplete'
              :is-disabled='disable'
            />
          </div>
          <div
            v-if='otpValidation'
            style='
              color: red;
              text-align: left;
              padding-left: 76px;
              padding-top: 5px;
            '
          >
            Please enter OTP
          </div>
          <div v-if='onTimerSet' class='mobile-otp-resend-text'>
            Resend OTP in
            <a-statistic-countdown
              v-model:value='deadline'
              format='ss'
              style='display: inline-block'
              @finish='onFinish'
            />
            seconds
          </div>
          <div
            v-else
            class='mobile-otp-resend-text'
            style='cursor: pointer'
            @click='onClickResend'
          >
            <u> Resend OTP</u>
          </div>
          <div>
            <a-button type='primary' @click='onClickContinue' :loading="loading"
              >Continue</a-button
            >
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { onMounted, ref, computed } from 'vue';
import { MobileOutlined } from '@ant-design/icons-vue';
import VOtpInput from 'vue3-otp-input';
import { useStore } from 'vuex';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';
import getLogRocket from '../../../services/logrocket';

export default {
  components: {
    MobileOutlined,
    VOtpInput,
  },
  setup() {
    const store = useStore();
    const disable = ref(false);
    const loading = ref(false);
    const logrocket = getLogRocket();

    onMounted(() => {
      window.scrollTo(0, 0);
      // services
      //   .getStages()
      //   .then((response) => {
      //     store.dispatch('onboardingStore/updateStage', response.data.data.stage);
      //   })
      //   .catch(() => {
      //   });
    });
    const mobileNumber = computed(
      () => store.getters['onboardingStore/getMobileNumber'],
    ).value;
    const router = useRouter();
    const otpInput = ref(null);
    const otpValidation = ref(false);
    let otpValue = 0;
    const onTimerSet = ref(true);
    const handleOnComplete = () => {
      otpValidation.value = false;
    };

    const deadline = ref(Date.now() + 60 * 1000);

    const handleOnChange = (value) => {
      otpValue = value;
      if (value.length) {
        otpValidation.value = false;
      }
    };

    const onClickChange = () => {
      router.push('/onboarding');
    };

    const onFinish = () => {
      onTimerSet.value = false;
    };
    const onClickContinue = () => {
      if (otpValue.length < 6) {
        otpValidation.value = true;
      } else if (!otpValue.length) {
        otpValidation.value = true;
      } else if (otpValue.length === 6) {
        const data = {
          mobile: mobileNumber,
          otp: otpValue,
        };
        loading.value = true;
        disable.value = true;
        services
          .mobileOtp(data)
          .then((response) => {
            loading.value = false;
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
            const { prefill } = response.data.data;
            logrocket.setUser(prefill.mobile, {
              mobile: prefill.mobile,
              name: prefill.name,
              email: prefill.email,
              currentStage: response.data.data.stage,
            });
          })
          .catch((error) => {
            loading.value = false;
            disable.value = false;
            const systemError = Operations.axiosErrorHandler(error);
            const message = systemError.status;
            const description = systemError.message
              ? systemError.message
              : '';
            Operations.notificationError(message, description);
          });
      }
    };

    const onClickResend = () => {
      const data = {
        mobile: mobileNumber,
      };
      services
        .mobileSignUp(data)
        .then(() => {
          onTimerSet.value = true;
          deadline.value = Date.now() + 60 * 1000;
        })
        .catch(() => {});
    };

    return {
      onClickContinue,
      handleOnComplete,
      handleOnChange,
      otpInput,
      otpValue,
      mobileNumber,
      onClickChange,
      otpValidation,
      deadline,
      onFinish,
      onTimerSet,
      onClickResend,
      disable,
      loading,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/mobileOtp.scss';
</style>
